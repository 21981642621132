.card-container{
    width: 100%;
    background-color: transparent;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 10px;
    position: relative;
}
.card-container > p:first-of-type {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
    gap: 5px;
}
.card-container > p:first-of-type > img {
    width: 20px;
    cursor: pointer;
}
.card-internal{
    padding-bottom: 20px;
}
.layout-cards{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 10px 5px;
    gap: 10px;
}
.layout-cards p {
    color: var(--primary);
}
.drag-scroll{
    overflow-y: auto;
    max-height: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 10px 5px;
    gap: 10px;
}
.email-crm{
    font-size: 11px !important;
    margin-top: -10px;
}
.email-crm:last-of-type{
    margin-top: -30px;
}
.drag-scroll::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
    background-color: #f1f1f127;
  }
  
  .drag-scroll::-webkit-scrollbar-track {
    border-radius: 10px;
    border: none;
  }
  
  .drag-scroll::-webkit-scrollbar-thumb {
    background-color: var(--primary); 
    border-radius: 10px; 
    cursor: pointer;
  }
.layout-cards > button:first-of-type{
    width: 100%;
    border: 1px dashed var(--primary);
    border-radius: 5px;
    padding: 0px 5px;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    color: var(--primary);
    background-color: transparent;
}
.grid{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}
.card-container > p:nth-of-type(2){
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
}
.card-container > p:nth-of-type(2) > span:first-of-type{
    font-weight: 600;
    margin-bottom: 0;
}
.card-container > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card-container > div > p {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 12px;
}
.card-container > div > p:first-of-type > img {
    width: 22px;
}
.card-container > div > p > img {
    width: 15px;
}
.filter-crm{
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    margin: 20px 0;
}
.filter-crm > div {
    display: flex;
    justify-content: center;
    align-items: center;
}
.filter-crm > div > div {
    display: flex;
    align-items: center;
}
.filter-crm input {
    border: none;
    background-color: transparent;
}
.filter-crm > div:last-of-type{
    gap: 2rem;
    width: 70%;
}
.filter-crm > div:first-of-type{
    border: 1px solid var(--primary);
    border-radius: 5px;
    padding: 0 5px 0 10px;
}
.filter-crm > div:last-of-type label{
    font-size: 12px;
    width: fit-content;
}
.search-internal{
    width: 300px !important;
}
.filter-crm > div:last-of-type select{
    border: none;
    background-color: transparent;
    border-radius: 0;
    font-size: 13px;
    padding: 0px 10px;
    width: 150px;
    min-width: 150px;
    border-bottom: 1px solid var(--primary);
    margin-left: 10px;
}
.filter-crm > div:last-of-type .ant-select{
    min-width: 150px;
    margin-left: 10px;
}

@media screen and (max-width: 1400px){
    .filter-crm > div:first-of-type input{
        padding: 0 5px 0 10px;
        font-size: 12px;
    }
    .filter-crm > div:last-of-type label{
        font-size: 11px;
        width: fit-content;
    }
    .filter-crm > div:last-of-type select{
        font-size: 12px;
        padding: 0px 7px;
        width: 150px;
    }
}

@media screen and (max-width: 700px){
    .filter-crm{
        gap: 0.3rem;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .filter-crm > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .filter-crm > div > div {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
    }
    .filter-crm > div:last-of-type{
        gap: 0.6rem;
        width: 100%;
        margin-top: 1rem;
    }
    .filter-crm > div:last-of-type select{
        padding: 0px 10px;
    }
    .filter-crm > div:last-of-type .ant-select{
        min-width: 100px;
        margin: 5px 0 0 5px;
        font-size: 12px;
    }
    .layout-cards > button:first-of-type{
        border: 1.3px dashed var(--primary);
    }
    .grid{
        overflow-x: auto;
    }
    .drag-scroll{
        overflow-y: auto;
        max-height: 60vh;
    }
    .layout-cards p {
        width: fit-content;
    }
    .layout-cards{
        min-width: 60%;
    }
}