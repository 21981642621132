.container-big-loader{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  left: 0;
  background-color: white;
  z-index: 20;
  overflow: hidden;
}
.header-big-loader{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  box-shadow: 0 0 10px 3px #dcdfe6;
}
.header-big-loader > img {
  width: 4%;
}
.big-loader {
    /*ackground-image: url('../../imgs/anim_3.gif') !important;
    background-size: cover;
    background-repeat: no-repeat; 
    background-position: center;*/
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-color: white;
    height: 100%;
    margin-top: 4rem;
    height: 80vh;
    z-index: 20;
  }
  .big-loader > img {
    position: absolute;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    width: 80%;
  }
  .big-loader > div:first-of-type{
    color: var(--primary);
    font-size: 24px;
    font-weight: 600;
  }
  .big-loader > p{
    color: var(--primary);
    font-size: 14px;
    width: 33%;
    margin-top: 20px;
  }

  @media screen and (max-width: 700px){
    .big-loader > img {
      height: 50%;
      width: auto;
    }
    .big-loader > div:first-of-type{
      color: var(--primary);
      font-size: 24px;
      font-weight: 600;
    }
    .big-loader > p{
      color: var(--primary);
      font-size: 14px;
      width: 90%;
      margin-top: 20px;
    }
    .header-big-loader > img {
      width: 15%;
    }
    .big-loader > div:nth-of-type(2){
      width: 90% !important;
    }
  }