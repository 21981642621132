.profile{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
}
.profile > div:first-of-type {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    box-shadow: 0 0 10px 2px rgba(128, 128, 128, 0.16);
    border-radius: 10px;
    padding: 20px;
    min-height: 60vh;
}
.profile > div:first-of-type > div:first-of-type{
    margin: 0 auto;
}
.profile > div:last-of-type{
    width: 55%;
    background-color: white;
    box-shadow: 0 0 10px 2px rgba(128, 128, 128, 0.16);
    border-radius: 10px;
    padding: 20px;
    min-height: 60vh;
    position: relative;
}
.profile > div:last-of-type  button{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 150px;
}
.profile-image-container{
    width: 200px;
    height: 200px;
    border-radius: 50%;
}
.profile-image-container img {
    width: 100%;
    border-radius: 50%;
    min-width: 100%;
    min-height: auto;
}
.profile-image-placeholder {
    position: relative;
    display: inline-block;
    width: 200px; /* Regola le dimensioni del placeholder */
    height: 200px; /* Regola le dimensioni del placeholder */
    border-radius: 50%; /* Crea un cerchio */
    background-color: #e0e0e0; /* Colore di sfondo del cerchio */
    cursor: pointer;
    border: 1px solid var(--primary);
  }
  
  .plus-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #333; /* Colore dell'icona */
    font-size: 24px; /* Dimensione dell'icona */
  }

  .profile .menu-item{
    border: 1px solid var(--primary);
    border-radius: 5px;
    font-size: 14px !important;
    padding: 6px 10px;
  }
  .profile .active{
    background-color: var(--primary);
    color: white;
  }
  .edit-profileimg{
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  @media screen and (max-width: 700px){
    .profile{
      flex-direction: column;
    }
    .profile > div:first-of-type {
      width: 100%;
      min-height: 40vh;
    }
    .profile > div:last-of-type{
        width: 100%;
        min-height: none;
    }
    .profile > div:last-of-type  button{
      top: 20px;
      width: 100px;
    }
}