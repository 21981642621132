.info-exam-candidate{
    margin-top: 1rem;
}
.info-exam-candidate .ant-table{
  background-color: transparent !important;
}
.dettagli-test-modal div h4:last-of-type{
  min-height: 33px;
}
.dettagli-test-modal{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.choose-visualize{
  position: fixed;
  top: 6rem;
}
.choose-visualize .ant-segmented-item-selected {
  color: white !important;
  padding: 4px 0 !important;
}
.choose-visualize .primary-outlined-btn{
  margin-left: 50px;
  background-color: var(--primary);
  padding: 4px 30px;
  align-items: center;
  font-size: 14px;
  border: none;
  justify-content: center;
  gap: 10px;
}
.choose-visualize .primary-outlined-btn img {
  margin-top: 0px;
  width: 15px;
  fill: white;
  margin-left: 5px;
}
.choose-visualize .ant-segmented-item {
  padding: 4px 0 !important;
}
.dettagli-test-modal div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
    width: 50%;
}
.dtm-desc div {
  width: 100%;
}
.filter-container{
  width: 100%;
}
.filter-container > div {
  width: 100%;
  margin: 20px 0;
}
.filter-container .ant-slider-track{
  background-color: var(--secondary) !important;
}
.filter-container .ant-slider-handle{
  border: 1px solid var(--secondary);
}
.filter-container .ant-slider-handle:hover{
  border: 2px solid var(--secondary);
}
.btn-search-name{
  background-color: var(--secondary);
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  min-width: 30px;
  max-width: 30px;
  cursor: pointer;
  margin-left: 10px;
}
.btn-search-name:nth-of-type(2){
  background-color: var(--primary);
}
.dettagli-test-modal div >h4:last-of-type{
    border: 1px solid var(--primary);
    border-radius: 5px;
    padding: 5px 8px;
    width: 100%;
    background-color: #edeffa;
}
.dettagli-test-modal div > h4:first-of-type{
    font-size: 600;
}
.punteggio-column-green{
  background-color: #4ba66553;
  color: #4BA665;
  padding: 8px 30px;
  border-radius: 5px;
  border: 1px solid #4BA665;
}
.punteggio-column-red{
  background-color: #f9595946;
  color: #F95959;
  padding: 8px 30px;
  border-radius: 5px;
  border: 1px solid #F95959;
}
.punteggio-crm-green{
  background-color: #4ba66553;
  color: #4BA665;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #4BA665;
  position: absolute;
  top: 10px;
  right: 10px;
}
.punteggio-crm-red{
  background-color: #f9595946;
  color: #F95959;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #F95959;
  position: absolute;
  top: 10px;
  right: 10px;
}
.time-column-green{
  background-color: #4ba66539;
  color: #4BA665;
  padding: 6px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid #4BA665;
}
.time-column-red{
  background-color: #f9595946;
  color: #F95959;
  padding: 6px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid #F95959;
}
.time-crm-green{
  background-color: #4ba66539;
  color: #4BA665;
  padding: 4px 4px;
  width: 100px;
  border-radius: 5px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid #4BA665;
}
.time-crm-red{
  background-color: #f9595946;
  color: #F95959;
  padding: 4px 4px;
  width: 100px;
  font-size: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid #F95959;
}
.note{
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0px 0 20px 0;
}
.note > textarea{
  width: 100% !important;
}
.note span {
  position: absolute;
  background-color: var(--secondary);
  border-radius: 30px;
  color: white;
  cursor: pointer;
  padding: 4px 10px;
  right: 3px;
  top: -10px;
  min-width: 100px;
  text-align: center;
}
.note label {
  font-weight: 600;
  text-align: left;
  margin-bottom: 15px;
}
.custom-download-link{
  position: absolute;
  bottom: 5px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
}
.ri-download-line{
  color: var(--secondary);
}
.time-column-red img, .time-column-green img,
.time-crm-red img, .time-crm-green img {
  width: 15px;
}
.ant-table-thead{
  background-color: transparent;
}
.ant-table-thead >tr > th {
  color: gray !important;
  font-size: 14px;
  text-align: center !important;
}
.ant-table-cell{
  font-size: 12px;
  color: var(--primary);
  text-align: center;
}

/*INFO CANDIDATO*/
.modal-candidate-top{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: 0 auto;
  }
  .answer-manual{
    text-align: left;
    padding: 5px;
  }
  .answer-manual > p {
    font-size: 13px;
    color: var(--primary);
  }
  .modal-candidate-top > hr {
    width: 60px;
    color: var(--primary);
    height: 0.8px;
    background-color: var(--primary);
    margin-top: 12px;
  }
  .modal-candidate-top > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .modal-candidate-top > div > span {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: gray;
  }
  .modal-candidate-top > div > p {
    color: #b4b4b4;
    font-size: 12px;
  }
  .modal-candidate-top > div.active > span {
    background-color: var(--secondary);
  }
  .modal-candidate-top > div.active > p {
    color: var(--secondary);
  }
  .modal-candidate-body h2{
    color: var(--primary);
    margin: 1rem 0;
  }

  .modal-punteggio{
    margin: 0 auto;
    margin-top: 1rem;
    background-color: var(--primary);
    padding: 6px 20px;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0px;
    width: 30%;
  }
  .modal-punteggio-summary{
    margin: 0 auto;
    margin-top: 1rem;
    padding: 6px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0px;
    width: 100%;
    transition: 0.3s all;
  }
  .modal-punteggio-summary > p:first-of-type{
    font-size: 14px;
    color: var(--secondary);
  }
  .modal-punteggio-summary span {
    border: none;
    background-color: transparent;
    color: var(--secondary);
    text-decoration: underline;
    font-size: 12px;
    cursor: pointer;
    margin-left: 10px;
  }
  .modal-punteggio-summary > p:last-of-type{
    color: var(--primary);
    font-size: 12px;
    margin: 0;
  }
  .modal-punteggio > p:first-of-type{
    font-size: 12px;
    color: white;
    margin: 0;
  }
  .modal-punteggio > p:last-of-type{
    color: white;
    font-size: 20px;
    margin: 0;
  }

  .modal-bar{
    width: 90%;
    margin-top: 1rem;
  }
  .modal-bar > p{
    color: var(--primary);
    margin-bottom: 0;
  }
  .modal-candidate-middle hr{
    width: 90%;
    color: #b4b4b4;
    margin: 0 auto;
    margin: 20px 0;
  }

  .modal-candidate-data{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 0.3rem;
  }
  .modal-candidate-data > div{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    margin: 8px 0;
  }
  .modal-candidate-data > div > p:first-of-type{
    font-weight: 600;
    color: var(--primary);
    font-size: 13px;
  }
  .modal-candidate-data > div > p:nth-of-type(2){
    color: gray;
    font-size: 13px;
  }
  .allegati{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    margin-top: 1rem;
  }
  .allegati img {
    width: 25px;
  }
  .add-allegato{
    color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    margin-left: 5px;
    margin-top: 0.5rem;
    margin-bottom: 40px;
  }
  .add-allegato span{
    font-size: 24px;
  }
  .modal-candidate-domande{
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:  0 auto;
    justify-content: flex-start;
    gap: 5px;
    max-height: 460px;
    overflow-y: auto;
    padding: 10px 20px;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .modal-candidate-domande::-webkit-scrollbar {
    width: 10px;
  }
  .modal-candidate-domande::-webkit-scrollbar-track{
    background-color: #edeffa;
    border-radius: 10px;
  }
  .modal-candidate-domande::-webkit-scrollbar-thumb{
    background-color: var(--primary);
    border-radius: 10px;
  }
  .modal-candidate-question{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    gap: 10px;
  }
  .modal-candidate-question img {
    width: 20px;
  }
  .modal-candidate-question > div:last-of-type{
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .modal-candidate-question > div:last-of-type > img{
    width: 16px;
    margin-right: 5px;
  }
  .modal-candidate-question > div:first-of-type{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
  }
  .modal-candidate-question > div:first-of-type > p{
    font-size: 12px;
    color: var(--primary);
  }
  .modal-candidate-question > div:last-of-type > span{
    font-size: 12px;
  }

  .modal-manual-question{
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid var(--primary);
    background-color: #edeffa;
    transition: 0.3s all;
    border-radius: 5px;
    padding: 12px 8px;
  }
  .modal-candidate-question-m{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    gap: 10px;
    cursor: pointer;
  }
  .modal-candidate-question-m img {
    width: 20px;
  }
  .modal-candidate-question-m > div:last-of-type{
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .modal-candidate-question-m > div:last-of-type > img{
    width: 16px;
    margin-right: 5px;
  }
  .modal-candidate-question-m > div:first-of-type{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
  }
  .modal-candidate-question-m > div:first-of-type > p{
    font-size: 12px;
    color: var(--primary);
  }
  .modal-candidate-question-m > div:last-of-type > span{
    font-size: 12px;
  }
  .cv-extract > div > h4{
    border-bottom: 1px solid rgba(2, 7, 68, 0.338);
    padding: 4px 10px;
    color: var(--primary);
    width: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.4rem;
    width: 80%;
    margin: 0 auto;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
  }
  .eduwork{
    width: 80%;
    margin: 0 auto;
  }
  .eduwork .ant-steps-item-description{
    color: var(--primary) !important;
    font-size: 13px !important;
  }
  .eduwork img {
    width: 30px;
  }
  .ant-steps-item-container{
    height: 60px;
  }
  .ant-steps-item-tail::after{
    height: 20px !important;
    border-color: var(--secondary);
    color: var(--secondary);
    background-color: var(--secondary) !important;
  }
  .cv-extract{
    width: 100%;
  }
  .cv-extract > div {
    width: 100%;
    padding: 10px 0;
  }
  .chart-container {
    width: 80%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 auto;
  }
  .chart-container > span {
    font-size: 11px;
    padding: 4px 10px;
    border-radius: 20px;
    color: white;
    margin: 5px;
  }
  .even-style{
    background-color: var(--primary);
  }
  .odd-style{
    background-color: var(--secondary);
  }
  @media screen and (max-width: 1400px) {

  }

  @media screen and (max-width: 700px){
    .modal-candidate-top{
      width: 75%;
    }
    .choose-visualize{
      position: fixed;
      top: 8rem;
    }
    .cet-exam{
      margin-top: 100px;
    }
  .modal-candidate-data{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 0.3rem;
  }
  .modal-candidate-data > div{
    width: 50%;
    align-items: flex-start;
    flex-direction: column;
    gap: 0rem;
    margin: 8px 0;
  }
  .modal-candidate-data > div > p:first-of-type{
    font-weight: 600;
    color: var(--primary);
  }
  .modal-candidate-data > div > p:nth-of-type(2){
    color: gray;
    word-wrap: break-word;
  }
  .ant-table{
    overflow-x: auto;
  }
  .dettagli-test-modal div h4:last-of-type{
    min-height: 33px;
}
  }