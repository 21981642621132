.home-out {
    overflow-x: hidden;
}
.home-out hr {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 3rem;
    margin-top: 3rem;
}
.hero-section{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin: 0 auto;
    gap: 0;
}
.hero-section > div{
    width: 50%;
}
.hero-section h1 {
    color: var(--primary);
    font-size: 40px;
}
.hero-section p {
    color: var(--primary);
    font-size: 18px;
    width: 80%;
}
.hero-section img {
    width: 100%;
    margin-top: 3rem;
}
.hero-section button:first-of-type,
.service-section button:first-of-type,
.service-section2 button:first-of-type,
.service-section3 button:first-of-type,
.service-section-contact button{
    background-color: var(--secondary);
    border-radius: 30px;
    border: none;
    color: white;
    padding: 8px 25px;
    cursor: pointer;
}
.hero-section button:last-of-type,
.service-section button:last-of-type,
.service-section2 button:last-of-type,
.service-section3 button:last-of-type{
    background-color: rgb(195, 195, 195);
    border-radius: 30px;
    border: none;
    margin: 20px 25px;
    color: var(--primary);
    padding: 8px 25px;
    cursor: pointer;
}

.service-section{
    background-color: #E7E8F5;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 2.5rem;
    padding: 3rem 0;
    margin-top: 5rem;
}
.service-section h2 {
    font-size: 30px;
    color: var(--primary);
}
.service-section p {
    color: var(--primary);
}
.service-section > p:first-of-type{
    margin-top: -30px;
}
.service-section > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    gap: 1rem;
}
.service-section > .faq-help-contatti{
    width: 100%;
}
.service-section >div > div {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
}
.service-section > div > div> img:first-of-type {
    width: 43%;
    height: auto;
}
.img-home-piccola{
    width: 35% !important;
}

.service-section2{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 3rem 0;
}
.service-section2 img {
    width: 40%;
}
.service-section2 > div > p:first-of-type,
.service-section3 > div > p:first-of-type{
    color: var(--secondary);
    font-size: 14px;
}
.service-section2 > div > h2,
.service-section3 > div > h2{
    color: var(--primary);
    font-size: 30px;
}
.service-section2 > div > p:last-of-type{
    color: var(--primary);
    font-size: 16px;
    width: 70%;
}

.service-section3{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0rem;
    padding: 3rem 0;
    width: 65%;
    margin: 0 auto;
}
.service-section3 img {
    width: 50%;
}
.service-section3 > div {
    width: 50%;
}
.service-section3 > div > p:last-of-type{
    color: var(--primary);
    font-size: 16px;
    width: 90%;
}

.service-section2:last-of-type img{
    width: 34%;
}
.service-section2:last-of-type{
    margin-left: 4rem;
    gap: 4rem;
}
.service-section2:last-of-type > div {
    width: 40% !important;
}
.service-section2:last-of-type > div > p:last-of-type{
    width: 80%;
}
.ss2-last{
    width: 70%;
    margin: 0 auto;
    gap: 4rem;
}
.ss2-last > div {
    margin-left: 4rem;
}

.come-funziona{
    text-align: center;
    padding: 3rem 0;
}
.come-funziona h2 {
    color: var(--primary);
    font-size: 30px;
}
.come-funziona > div {
    border: 2px solid var(--secondary);
    border-radius: 10px;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0.5rem;
    margin: 0 auto;
    margin-top: 40px;
    height: 550px;
}
.come-funziona iframe {
    width: 90%;
    height: 90%; 
}

.footer1{
    text-align: center;
    color: white;
    position: relative;
    padding: 3.5rem 0;
    background-image: url(../imgs/footer1.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    flex-direction: column;
}
.footer1 h2 {
    color: white;
    font-size: 30px;
    z-index: 10;
}
.footer1 button:first-of-type{
    background-color: white;
    border-radius: 30px;
    border: none;
    color: var(--secondary);
    padding: 8px 25px;
    cursor: pointer; 
    z-index: 10;
}
.footer1 p {
    font-size: 16px;
}
.footer1 button:last-of-type{
    background-color: transparent;
    border-radius: 30px;
    border: 1px solid rgb(195, 195, 195);
    margin: 20px 25px;
    color: white;
    padding: 8px 25px;
    cursor: pointer;
    z-index: 10;
}



.faq-help-contatti{
    width: 100%;
}
.faq-help-contatti .ant-collapse{
    background-color: transparent;
    border: none;
    text-align: left;
}
.faq-help-contatti .ant-collapse-item{
    color: var(--primary);
    border: none;
    width: 100%;
}
.faq-help-contatti .ant-collapse-item-active{
    background-color: var(--primary) !important;
    color: white;
}
.faq-help-contatti .ant-collapse-item-active span{
    color: white !important
}
.service-section-contact{
    text-align: center;
    width: 30%;
    margin: 0 auto;
    margin-top: 3rem;
    margin-bottom: 3rem;
}
.service-section-contact >p:first-of-type{
    color: var(--secondary);
}
.service-section-contact > h2 {
    font-size: 30px;
    color: var(--primary);
}
.service-section-contact > p:last-of-type{
    color: var(--primary);
    margin: 20px 0 5px 0;
    font-size: 18px;
}
.service-section-contact input,
.service-section-contact textarea{
    margin: 5px 0 20px 0;
    width: 90%;
}
.service-section-contact textarea{
    min-height: 200px;
}
@media screen and (max-width: 650px){
    .home-out hr {
        width: 90%;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
    .hero-section{
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        gap: 0;
        margin-top: 2rem;
        padding: 0 10px;
    }
    .hero-section > div{
        width: 100%;
    }
    .hero-section p {
        width: 90%;
    }

    .service-section {
        text-align: left;
        padding: 3rem 1.2rem;
    }
    .service-section p {
        color: var(--primary);
        font-size: 18px;
    }
    .service-section > div:first-of-type {
        flex-direction: column;
        width: 80%;
        gap: 1.4rem;
    }
    .service-section > div:last-of-type {
        width: 100%;
    }
    .service-section >div > div {
        width: 100%;
        flex-direction: column;
        text-align: center;
        gap: 2rem;
    }
    .service-section > div > div> img:first-of-type {
        width: 45%;
        height: auto;
    }
    .img-home-piccola{
        width: 37% !important;
    }

    .service-section2{
        flex-direction: column-reverse;
        gap: 2rem;
        padding: 3rem 2rem;
    }
    .service-section2 img {
        width: 100%;
    }
    .service-section2 > div > p:last-of-type{
        color: var(--primary);
        font-size: 16px;
        width: 90%;
    }

    .service-section3{
        flex-direction: column;
        padding: 3rem 2rem;
        width: 100%;
    }
    .service-section3 img {
        width: 90%;
    }
    .service-section3 > div {
        width: 100%;
    }
    .service-section3 > div > p:last-of-type{
        width: 100%;
    }

    .service-section2:last-of-type > div > p:last-of-type{
        width: 100%;
        font-size: 16px;
    }
    .ss2-last img {
        width: 90%;
    }
    .ss2-last{
        width: 100%;
        margin: 0 auto;
        gap: 4rem;
        padding: 3rem 2rem;
    }
    .ss2-last > div {
        margin-left: 0;
    }
    
    .come-funziona > div {
        border: 2px solid var(--secondary);
        border-radius: 10px;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem 0.5rem;
        margin: 0 auto;
        margin-top: 40px;
        height: auto;
    }
    .come-funziona iframe {
        width: 90%;
        height: 90%; 
    }
    
    .footer1{
        text-align: center;
        color: white;
        position: relative;
        padding: 3rem 2rem;
        background-image: url(../imgs/footer1.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3rem;
        flex-direction: column;
    }
    .footer1 h2 {
        color: white;
        font-size: 30px;
        z-index: 10;
    }
    .footer1 button:first-of-type{
        background-color: white;
        border-radius: 30px;
        border: none;
        color: var(--secondary);
        padding: 8px 25px;
        cursor: pointer; 
        z-index: 10;
    }
    .footer1 p {
        font-size: 16px;
    }
    .footer1 button:last-of-type{
        background-color: transparent;
        border-radius: 30px;
        border: 1px solid rgb(195, 195, 195);
        margin: 20px 25px;
        color: white;
        padding: 8px 25px;
        cursor: pointer;
        z-index: 10;
    }

    .faq-help-contatti .ant-collapse-item-active p {
        font-size: 14px !important;
    }
    .faq-help-contatti .ant-collapse-item p {
        font-size: 14px !important;
    }
    .service-section-contact{
        width: 90%;
    }
}

@media screen and (min-width: 651px) and (max-width:950px){
    .hero-section{
        width: 90%;
        margin-top: 2rem;
    }
    .hero-section > div:first-of-type{
        width: 60%;
    }
    .hero-section p {
        width: 90%;
    }

    .service-section > div {
        width: 90%;
    }
    
    .service-section2 img {
        width: 45%;
    }
    .service-section2 > div > p:last-of-type{
        width: 80%;
    }

    .service-section3{
        width: 80%;
    }
    .service-section3 > div {
        width: 60%;
    }
    .service-section3 > div > p:last-of-type{
        width: 100%;
    }
    
    .ss2-last{
        width: 90%;
        gap: 0rem;
    }
    .ss2-last > div {
        margin-left: 1.4rem;
    }
    
    .come-funziona{
        text-align: center;
        padding: 3rem 0;
    }
    .come-funziona > div {
        width: 90%;
        height: 450px;
    }
    .come-funziona iframe {
        width: 90%;
        height: 90%; 
    }
    
    .footer1{
        text-align: center;
        color: white;
        position: relative;
        padding: 3.5rem 0;
        background-image: url(../imgs/footer1.png);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 600px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3rem;
        flex-direction: column;
    }
    .footer1 h2 {
        color: white;
        font-size: 30px;
        z-index: 10;
    }
    .footer1 button:first-of-type{
        background-color: white;
        border-radius: 30px;
        border: none;
        color: var(--secondary);
        padding: 8px 25px;
        cursor: pointer; 
        z-index: 10;
    }
    .footer1 p {
        font-size: 16px;
    }
    .footer1 button:last-of-type{
        background-color: transparent;
        border-radius: 30px;
        border: 1px solid rgb(195, 195, 195);
        margin: 20px 25px;
        color: white;
        padding: 8px 25px;
        cursor: pointer;
        z-index: 10;
    }
    .faq-help-contatti .ant-collapse-item-active p {
        width: 100%;
    }
    .faq-help-contatti .ant-collapse-item p {
        font-size: 14px !important;
    }
    .service-section-contact{
        width: 60%;
    }
}