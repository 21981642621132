.footer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 3rem;
    color: white;
}
.footer span {
    margin-left: 20px;
}
.footer p {
    margin-top: 20px;
}
.footer div:last-of-type{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    justify-content: flex-start;
}
.footer a {
    color: white;
    text-decoration: none;
}
.footer img {
    width: 180px;
}

@media screen and (max-width: 600px){
    .footer{
        padding: 3rem 1rem;
    }
    .footer img {
        width: 100px;
    }
}