/*DOMANDE CONTAINER*/
.domande-container-save {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    margin-top: 50px;
    position: relative;
    width: 100%;
    
}
.domande-container-save > a {
  color: var(--primary);
  font-size: 14px;
  text-decoration: underline;
  position: absolute;
  left: 3rem;
  top: -2rem;
}
.domande-container-save > a:hover{
  color: var(--primary);
}
.domande-container-save > a > img {
  width: 20px;
}
.domande-container-save > button{
    padding: 10px 30px;
    margin: 0 auto;
    border: none;
    cursor: pointer;
    background-color: var(--primary);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 3rem;
    top: -2rem;
}
.domande-container-save > button > img {
  margin-right: 10px;
}
.domande-container {
    display: flex;
    height: 370px;
    max-height: 600px;
    margin-top: 2.5rem;
    gap: 3rem;
    width: 95%;
  }
  
  .lista-domande {
    width: 40%;
    overflow-y: auto;
    padding: 0 5px 0 5px;
  }
  .lista-domande::-webkit-scrollbar,
  .domanda-attuale::-webkit-scrollbar {
    width: 12px;
    border-radius: 10px;
    background-color: #f1f1f127;
  }
  
  .lista-domande::-webkit-scrollbar-track,
  .domanda-attuale::-webkit-scrollbar-track {
    border-radius: 10px;
    border: none;
  }
  
  .lista-domande::-webkit-scrollbar-thumb,
  .domanda-attuale::-webkit-scrollbar-thumb {
    background-color: var(--primary); 
    border-radius: 10px; 
    cursor: pointer;
  }
  .domanda-item > img:first-of-type{
    width: 15px;
  }
  .domanda-item > img:nth-of-type(2){
    width: 15px;
    margin: 0 10px;
  }
  .drag-handle{
    font-size: 20px;
    width: 5%;
    cursor: grab;
  }
  .domanda-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
  }
  .domanda-item.dragging {
    transform: translateY(-5px); 
  }
  .domanda-item > p {
    width: 80%;
    margin-left: 10px;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }
  .domanda-item:hover {
    background-color: #e4ecf2;
  }
  
  .domanda-attuale {
    width: 70%;
    padding: 20px;
    position: sticky;
    top: 0;
    overflow-y: auto;
  }
  .domanda-attuale > p {
    color: var(--primary);
    font-size: 18px;
    font-weight: 500;
    width: 60%;
    display: flex;
    gap: 10px;
  }
  .risposta{
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
    padding: 6px 10px;
    border: 1.3px solid var(--primary);
    margin: 10px 0;
    border-radius: 5px;
    background-color: #edeffa;
    width: 70%;
    color: var(--primary);
    font-size: 14px;
  }
  .risposta > span {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--primary);
    border-radius: 5px;
    background-color: white;
    color: var(--primary);
    font-size: 12px;
    font-weight: 600;
  }
  .opzioni {
    list-style-type: none;
    padding: 0;
  }
  .domanda-selected{
    background-color: #e4ecf2;
    border: 1px solid var(--primary);
  }
  .risposta-corretta {
    margin: 10px 0;
    background-color: var(--primary);
    color: white;
    border-radius: 5px;
    padding: 4px 10px;
  }
  .create-exam-top{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55%;
    margin: 0 auto;
    margin-top: 0.5rem;
  }
  .add-our-question{
    position: absolute;
    left: 2rem;
    top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-color: var(--secondary);
    border-radius: 5px;
    padding: 5px 10px;
    color: white;
    cursor: pointer;
  }
 .ck-powered-by{
  display: none !important;
 }
  .create-exam-top2{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin-top: 0.5rem;
  }
  .create-exam-top > hr,
  .create-exam-top2 > hr {
    width: 80px;
    color: var(--primary);
    height: 0.8px;
    background-color: var(--primary);
    margin-top: 12px;
  }
  .create-exam-top > div,
  .create-exam-top2 > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .create-exam-top > div > span,
  .create-exam-top2 > div > span {
    display: inline-block;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: gray;
  }
  .create-exam-top > div > p,
  .create-exam-top2 > div > p {
    color: #b4b4b4;
  }
  .create-exam-top > div.active > span,
  .create-exam-top2 > div.active > span {
    background-color: var(--secondary);
  }
  .create-exam-top > div.active > p,
  .create-exam-top2 > div.active > p {
    color: var(--secondary);
  }
  .copy-link{
    border: 1px solid #b4b4b4;
    background-color: transparent;
    color: #b4b4b4;
    border-radius: 5px;
    cursor: not-allowed;
    padding: 6px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 200px;
  }
  .copy-preview img {
    width: 20px;
  }
  .copy-preview{
    position: absolute;
    right: 3rem;
    top: 40px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .copy-link-active{
    border: 1px solid var(--primary);
    background-color: transparent;
    color: var(--primary);
    border-radius: 5px;
    cursor: pointer;
    padding: 6px 12px;
    display: flex;
    justify-content: center;
    gap: 15px;
    align-items: center;
    width: 200px;
  }
  .copy-link-track{
    border: 1px solid var(--primary);
    background-color: transparent;
    color: var(--primary);
    border-radius: 5px;
    cursor: pointer;
    padding: 6px 12px;
    display: flex;
    justify-content: center;
    gap: 15px;
    align-items: center;
  }
  .preview{
    background-color: transparent;
    text-decoration: underline;
    color: var(--primary);
    border-radius: 5px;
    cursor: pointer;
    padding: 0px 6px;
    display: flex;
    justify-content: center;
    gap: 15px;
    align-items: center;
  }
  .preview-disabled{
    background-color: transparent;
    text-decoration: underline;
    color: #D9D9D9;
    cursor: not-allowed;
    border-radius: 5px;
    cursor: pointer;
    padding: 0px 6px;
    display: flex;
    justify-content: center;
    gap: 15px;
    align-items: center;
  }
  .preview-disabled:hover{
    color: #D9D9D9;
    cursor: not-allowed;
    text-decoration: underline;
  }
  .create-exam-body{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .create-exam-form{
    margin-top: 2rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100% !important;
  }
  .create-exam-form > div {
    width: 60%;
  }
  .tag-skills-input{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
  .create-exam-body .ant-select-selector{
    min-height: 40px;
  }
 .ant-styiling > div{
    height: 40px !important;
    padding: 4px 20px !important;
    border: 1.5px solid #DDDDDD;
    width: 100%;
    border-radius: 5px;
    outline: none;
    transition: 0.2s all;
}
  .candidati-add-exam{
    text-align: center;
  }
  .candidati-add-exam h4 {
    width: 60%;
    margin: 0 auto;
  }
  .candidati-add-exam h2 {
    font-weight: 600;
    text-decoration: underline;
  }
  .candidati-add-exam > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    width: 50%;
    margin: 0 auto;
    margin-top: 3rem;
  }

  /*ADD EDIT QUESTION*/
  .edit-correct{
    border: 1px solid var(--primary) !important;
  }
  .div-not-edit{
    position: relative;
    width: 50%;
  }
  .div-not-edit img,
  .div-correct-not-edit img {
    position: absolute;
    top: 0px;
    left: -8px;
    width: 17px;
    z-index: 1000;
    cursor: pointer;
  }
  .div-correct-not-edit{
    position: relative;
    width: 50%;
  }
  .risposta-edit{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    padding: 6px 8px;
    border: 1.3px solid var(--primary);
    margin: 10px 0;
    border-radius: 5px;
    background-color: #edeffa;
    width: 100%;
    color: var(--primary);
    font-size: 13px;
    min-height: 60px;
  }
  .risposta-edit > span,
  .risposta-edit-edit > span {
    width: 20px;
    min-height: 20px;
    min-width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--primary);
    border-radius: 5px;
    background-color: white;
    color: var(--primary);
    font-size: 10px;
    font-weight: 600;
  }
  .risposta-corretta-edit {
    margin: 10px 0;
    background-color: var(--primary);
    color: white;
    border-radius: 5px;
    padding: 4px 8px;
    width: 100%;
    min-width: 100%;
  }
  .risposta-corretta-edit-edit {
    margin: 10px 0;
    background-color: var(--primary);
    color: white;
    border-radius: 5px;
    padding: 4px 8px;
    width: 50%;
    min-width: 50%;
  }
  .risposta-edit-edit{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    padding: 0px 8px;
    border: 1.3px solid var(--primary);
    margin: 10px 0;
    border-radius: 5px;
    background-color: #edeffa;
    width: 50%;
    color: var(--primary);
    font-size: 13px;
    min-height: 60px;
  }
  .risposta-edit-edit input,
  .div-question-edit input{
    border: none;
    background-color: transparent;
    padding-right: 6px;
    padding-left: 6px;
    width: 100%;
  }
  .div-question-edit > * {
    width: 100%;
  }
  .risposta-edit-edit .img-set-correct {
    width: 22px;
    z-index: 1000;
    cursor: pointer;
  }
  .choose-num{
    justify-content: center;
    margin-bottom: 30px;
  }
  .numQuestions{
    width: 10%;
    background-color: #dcdfff;
    border-radius: 30px;
    margin: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 30px;
  }
  .numQuestions p,
  .active-numQuestions p {
    display: flex;
    align-items: center;
    margin-top: 13px;
  }
  .active-numQuestions{
    cursor: pointer;
    width: 10%;
    background-color: var(--secondary);
    color: white;
    border-radius: 30px;
    margin: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
  }
  .div-question-edit{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 8px;
    border: 1.3px solid var(--primary);
    margin: 10px 0;
    border-radius: 5px;
    background-color: #edeffa;
    position: relative;
    width: 100%;
    color: var(--primary);
    font-size: 14px;
    min-height: 60px;
  }
  .div-question-edit img {
    position: absolute;
    top: -10px;
    left: -8px;
    width: 17px;
    z-index: 1000;
    cursor: pointer;
  }

  .tracklink-modal-container{
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .tracklink-modal-container div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 7px;
    width: 28%;
  }
  .tracklink-modal-container div img {
    display: none;
    width: 20px;
    z-index: 1000;
    cursor: pointer;
  }
  .tracklink-modal-container div:hover img {
    display: block;
  }
  .tracklink-modal-add{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
    gap: 1rem;
  }

  .moduli{
    text-align: center;
  }
  .moduli-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 2rem;
    margin-top: 2rem;
  }
  .moduli-container > div {
    width: 30%;
    min-height: 200px;
    border-radius: 10px;
    border: 1px solid var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
.moduli-container h4 {
  font-weight: 600;
  font-size: 17px;
  color: var(--primary);
}


  @media screen and (max-width: 1400px) {
    .domande-container-save > a {
      color: var(--primary);
      font-size: 14px;
      text-decoration: underline;
      position: absolute;
      left: 3rem;
      top: -2rem;
    }
    .create-exam-top{
      width: 60%;
    }
    .create-exam-top > div > p,
    .create-exam-top2 > div > p {
      font-size: 13px;
      margin-top: 3px;
    }
    .copy-link{
      border: 1px solid #b4b4b4;
      background-color: transparent;
      color: #b4b4b4;
      border-radius: 5px;
      cursor: not-allowed;
      padding: 6px 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      width: 170px;
    }
    .copy-preview button {
      font-size: 13px;
    }
    .copy-preview img {
      width: 20px;
    }
  }


  /*MODULI*/
  .domande-moduli-nostri{
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 1rem;
  }
  .domande-moduli-nostri > div {
    display: flex;
    flex-direction: column;
    width: 40%;
  }
  .segmented{
    margin: 0 auto;
    width: 70%;
  }
  .domande-moduli-nostri > div > h4 {
    color: var(--primary);
    font-size: 18px;
  }
  .domande-cont{
    overflow-y: auto;
    max-height: 80vh;
    padding-right: 10px;
  }
  .domande-cont::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
    background-color: #f1f1f127;
  }
  
  .domande-cont::-webkit-scrollbar-track {
    border-radius: 10px;
    border: none;
  }
  
  .domande-cont::-webkit-scrollbar-thumb {
    background-color: var(--primary); 
    border-radius: 10px; 
    cursor: pointer;
  }
  .domande-cont > div {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border: 1px solid var(--primary);
    border-radius: 10px;
    margin: 10px 0;
    padding: 10px;
    background-color: #e4ecf2;
  }
  .domande-cont p{
    font-size: 12px;
    width: 80%;
  }
  .domande-cont span{
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: var(--secondary);
    width: 30px;
    min-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    min-height: 30px;
    cursor: pointer;
    font-size: 19px;
  }
  .option-domande-cont{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .option-domande-cont p {
    width: 45%;
  }


  @media screen and (max-width: 700px){
    .copy-preview{
      left: 0rem;
      top: 0px;
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
    }
    .copy-link{
      width: auto;
    }
    .copy-link-active{
      width: auto;
    }
    .create-exam-top{
      width: 100%;
      margin-top: 3rem;
      gap: 20px;
    }
    .create-exam-top > hr {
      width: 20px;
      display: none;
    }
    .domande-container {
      display: flex;
      flex-direction: column;
      height: auto;
      max-height: none;
      margin-top: 1rem;
      gap: 3rem;
      width: 100%;
    }
    .lista-domande {
      width: 100%;
      overflow-x: auto;
      display: flex;
    }
    .domanda-attuale {
      width: 100%;
    }
    .domanda-attuale > p {
      width: 100%;
    }
    .risposta{
      width: 100%;
    }
    .domanda-item {
      justify-content: flex-start;
      position: relative;
      align-items: center;
      flex-direction: column;
      min-width: 70%;
      padding-top: 30px;
    }
    .domanda-item > img:first-of-type{
      top: 10px;
      left: 25%;
      position: absolute;
    }
    .domanda-item > img:nth-of-type(2){
      position: absolute;
      top: 10px;
      left: 40%;
    }
    .domanda-item > img:last-of-type{
      position: absolute;
      top: 10px;
      left: 65%;
    }
    .domande-container-save > a {
      left: 0rem;
      top: -2rem;
    }
    .flex-mobile > div{
      width: 100%;
    }
    .risposta-edit-edit{
      justify-content: space-between;
    }
    .add-our-question{
      left: 90%;
      top: 0px;
      font-size: 14px;
      position: absolute;
      padding: 5px 10px;
      z-index: 5;
    }
    .create-exam-form > div {
      width: 100%;
    }
    .create-exam-form button{
      width: 50%;
    }
    .create-exam-form .ant-select{
      width: 100% !important;
    }
    .domande-container-save > button{
      padding: 6px 15px;
      margin: 0 auto;
      border: none;
      cursor: pointer;
      background-color: var(--primary);
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0rem;
      top: -2.2rem;
      font-size: 14px;
    }
    .domande-container-save{
      margin-top: 70px;
    }
    .domande-container-save button > img{
      width: 20px;
    }
    .domande-moduli-nostri{
      justify-content: flex-start;
      gap: 1rem;
      align-items: flex-start;
      flex-direction: column;
    }
    .domande-moduli-nostri > div {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .segmented{
      margin: 0 auto;
      width: 100%;
      overflow-x: auto;
      padding-bottom: 5px !important;
    }
    .domande-moduli-nostri > div > h4 {
      color: var(--primary);
      font-size: 18px;
    }
    .candidati-add-exam h4 {
      width: 100%;
      text-align: left;
    }
    .candidati-add-exam h2 {
      width: 100%;
    }
    .candidati-add-exam > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;
      width: 100%;
      margin: 0 auto;
      margin-top: 3rem;
    }
    .candidati-add-exam > div > p{
      text-align: left;
    }
  }

